<!--
 * @Description: 查看表单管理
* @Autor: YuYang
 * @Date: 2024-03-05 16:00:34
 * @LastEditors: YuYang
 * @LastEditTime: 2024-03-05 16:00:34
-->
<template>
	<div class="m20 p20 bg-white">
		<div class="mb20">
			<!--      <el-button
        size='small'
        class='f-white bg-theme'
        @click="add"
		>发布</el-button> -->
		</div>

		<!-- 表格 -->
		<!-- 参考文档 https://blog.csdn.net/weixin_45288172/article/details/130949949 -->
		<div class="mt30">
			<el-table :data="list" :header-cell-style="{background:'#f7f8fa',color:'#323233',fontWeight:500}"
				style="width: 100%">
				<el-table-column prop="formTitle" label="表单标题" align='center' header-align='center'>
				</el-table-column>

				<el-table-column prop="userId" label="用户ID" align='center' header-align='center'>
				</el-table-column>

				<el-table-column prop="submitDate" label="提交时间" align='center' header-align='center'>
				</el-table-column>

				<el-table-column prop="showTitle" label="提交内容" align='center' header-align='center'>
				</el-table-column>

				<!-- 

				<el-table-column v-for="item in list.fieldValueList" :key="item"  label="666666" align="center">

				</el-table-column> -->


				<!--  <el-table-column v-for="(item, index) in list.fieldValueList" :key="index" :label="item" >
              数据的遍历  scope.row就代表数据的每一个对象
              <template slot-scope="scope">
               <span>{{scope.row.list[index].value}}</span>
              </template>
            </el-table-column> -->


				<!-- 				<el-table-column v-for="(item,index) in fieldValueList" :key="index" label="内容" align='center'
					header-align='center'>
				</el-table-column> -->


				<el-table-column label="操作" align='center' header-align='center'>
					<template slot-scope="scope">
						<!-- 					<span class="mr5 f-theme pointer" @click="edit(scope.row)">查看</span>
						<span class="ml5 mr5 f-theme pointer" @click="copy(scope.row)">复制</span> -->
						<span class="ml5 mr5 f-theme pointer" @click="del(scope.row)">删除</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";

	import {
		getFormList,
		deleteForm
	} from "@/api/form";

	export default {
		name: "formManager",

		created() {
			this.getList();
		},

		data() {
			return {
				list: [],
			};
		},

		computed: {
			...mapGetters(["project"]),
		},

		methods: {
			//加载表单信息
			async getList() {
				let {
					status,
					list,
					message
				} = await getFormList({
					projectId: this.project.id
				});
				//console.log('--->', this.project.id, list);
				if (status == "10000") {
					this.list = list;
				} else {
					if (status == "500") //jwt expired
					{
						message = "您的账号已经在别的电脑登录,当前登录已失效";
					}
					this.$notify({
						title: "失败",
						message: message,
						type: "warning",
					});
				}

			},

			add() {
				this.$router.push({
					name: "form-edit"
				});
			},

			edit(params) {
				this.$router.push({
					name: "form-edit",
					params: this.$cloneDeep(params),
				});
			},
			//删除提交的表单
			del(params) {
				let that = this;
				console.log(params);
				console.log(params.id);
				let id = params.id;
				this.$confirm("您确认要删除该表单?", "删除表单", {
					confirmButtonText: "确定删除",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					//数据库找到记录删除
					that.deleteForm(id);


				});


			},
			//删除提交的表单
			async deleteForm(id) {
				let {
					status,
					message
				} = await deleteForm({
					projectId: this.project.id, //商户号 防止篡改数据
					id: id,
				});

				if (status == "10000") {
					//页面刷新
					this.getList();
				} else {
					this.$notify({
						title: "失败",
						message: message,
						type: "success",
					});
				}

			}

		},
	};
</script>